(function() {
	'use strict';

	if ($('.slider-sliderSliNotiSecSm').length > 0) {
		var sliderInfNotiSecMed = tns({
			"container": ".slider-sliderSliNotiSecSm",
			"controlsContainer": ".slider-sliderSliNotiSecSm-controls",
			"preventScrollOnTouch": "force",
			"controlsPosition": "bottom",
			"controls": false,
			"navPosition": "bottom",
			"mouseDrag": true,
			"speed": 400,
			"center": false,
			"slideBy": 1,
			"fixedWidth": 250,
			"gutter": 16,
			"loop": false,
			"responsive": {
				"768": {
					"slideBy": 1,
					"controls": false,
					"fixedWidth": 316,
					"gutter": 32
				},
				"1280": {
					"slideBy": 1,
					"controls": true,
					"fixedWidth": 316,
					"gutter": 64
				},
				"1600": {
					"slideBy": 1,
					"controls": true,
					"fixedWidth": 316,
					"gutter": 64
				}
			}
		});
	}

})();


//
//	Jumpshot – Banner –– GLOWEB-8731
//
$(document).ready(function(){

	// Get DOM reference to the banner
	var jumpshotBanner = document.querySelector('.jumpshot-banner');

	// Don't show the Banner to visitors, which already interacted with it (by clicking the "here" link or the "close" button)
	if (avastGlobals.web.locale == 'en-us' && jumpshotBanner !== null && localStorage.getItem('visitorInteractedWithJumpshotBanner') === null) {

		// Show the banner
		jumpshotBanner.style.display = 'block';

		// Check if Performance cookies are enabled
		function performanceCookiesEnabled() {
			var
				activeGroups = window.OptanonActiveGroups,
				performanceCookiesGroupID = 'C0002';	// Optanon code for Performance cookies

			// Ensure activeGroups is defined and is a string
			if (typeof activeGroups === 'string') {
				// Check if the groupID is present in the array
				return activeGroups.split(',').includes(performanceCookiesGroupID);
			}
			return false;
		}

		//	Banner –– Link
		jumpshotBanner.querySelector('a').addEventListener('click', function(e){
			// Set indicator of visitor's interaction with the banner
			localStorage.setItem('visitorInteractedWithJumpshotBanner', 'true');

			// Track visitor only if Performance Cookies are enabled
			if (performanceCookiesEnabled()) {
				sdl.push({
					user: {
						click: {
							element: {
								actionType: 'click', // 👉 TODO: Verify with @OndrejSubrt
								component: 'link', // 👉 TODO: Verify with @OndrejSubrt
								path: '/' + sdlObj.screen.locale + '/link', // 👉 TODO: Verify with @OndrejSubrt
								id: 'jumpshot-banner' // 👉 TODO: Verify with @OndrejSubrt
							}
						}
					},
					event: 'user.click.element'
				});
			}
		});

		//	Banner – Close button
		jumpshotBanner.querySelector('button').addEventListener('click', function (e) {
			// Set indicator of visitor's interaction with the banner
			localStorage.setItem('visitorInteractedWithJumpshotBanner', 'true');

			// Hide the banner
			jumpshotBanner.classList.add('hide');

			// Track visitor only if Performance Cookies are enabled
			if (performanceCookiesEnabled()) {
				sdl.push({
					user: {
						click: {
							element: {
								actionType: 'close',
								component: 'cmp-alert', // 👉 TODO: Verify with @OndrejSubrt
								path: '/' + sdlObj.screen.locale + '/cmp-alert', // 👉 TODO: Verify with @OndrejSubrt
								id: 'jumpshot-banner' // 👉 TODO: Verify with @OndrejSubrt
							}
						}
					},
					event: 'user.click.element'
				});
			}
		});
	}
});
